import { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Table,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { atualizaUsuario, checkMaxPessoas, deletaUsuario, listaUsuariosFilter, resetAcessoUsuario } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { UserForm } from './components/UserForm';
import { CollaboratorRow } from './components/CollaboratorRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { WorkerIcon } from '../../../common/Icons';
import { Pagination } from '../../../common/Pagination';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions.js'
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled.jsx';
import { UserModalInfo } from './components/UserModalInfo.jsx';
import { listaEmpresas } from '../companies/configs/functions.js';
import { CollaboratorsTableFilter } from './components/CollaboratorsTableFilter';
import { FiltersButton } from '../../../common/FiltersButton.jsx';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow.jsx';
import ConfirmDialog from '../../../common/ConfirmDialog.jsx';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable.jsx';

function ListOfCollaborators() {
    const queryClient = useQueryClient();
    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();

    const [searchParams, setSearchParams] = useSearchParams();

    const [open, setFormOpen] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDialogResetUser, setOpenDialogResetUser] = useState(false);
    const [turnAdmin, setTurnAdmin] = useState(false);

    const searchParamPage = searchParams.get('page');
    const searchParamPerPage = searchParams.get('perPage');

    // estados para controle de paginacao
    const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
    const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    const [user, setUser] = useState(null);

    const columns = useMemo(() => [
        { field: 'matricula', headerName: 'Matrícula', minWidth: 120, flex: 0.5 },
        { field: 'nome', headerName: 'Nome', minWidth: 150, flex: 0.5 },
        { field: 'cpf', headerName: 'CPF', minWidth: 150, flex: 0.5 },
        { field: 'dtNascimento', headerName: 'Data de Nascimento', minWidth: 150, flex: 0.5 },
        { field: 'empresa.fantasyName', headerName: 'Empresa', minWidth: 150, flex: 0.5, valueGetter: (params) => params.row.empresa?.fantasyName },
        { field: 'status', headerName: 'Status', minWidth: 120, flex: 0.5, valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const { data: empresasData } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: listaEmpresas,
        staleTime: Infinity,
    });

    const queryFilters = {
        tipo_usuario: 'colaborador',
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        ...Object.fromEntries(searchParams),
    };

    const queryKeyListCollaborators = ['listOfCollaborators', queryFilters];

    const { data: collaboratorsData, isLoading, isPending } = useQuery({
        queryKey: queryKeyListCollaborators,
        queryFn: () => listaUsuariosFilter(queryFilters),
        enabled: !!searchParamPage && !!searchParamPerPage,
    });

    const collaborators = collaboratorsData?.data?.data ?? [];
    const numTotalItems = collaboratorsData?.data?.numero_total ?? 0;
    const isLoadingList = isLoading || isPending;

    const { data: isMaxCollaborators } = useQuery({
        queryKey: ['checkMaxCollaborators'],
        queryFn: () => checkMaxPessoas(numTotalItems),
        enabled: !!numTotalItems,
        refetchOnWindowFocus: false,
    });

    function handleViewUser(user) {
        setUser(user);
        setOpenViewModal(true);
    }

    function handleEditUser(user) {
        setUser(user);
        setFormOpen(true);
    }

    const handleOpenDeleteDialog = (item) => {
        setUser(item);
        setOpenDeleteDialog(true);
    }

    const handleOpenDialogResetUser = (item) => {
        setUser(item);
        setOpenDialogResetUser(true);
    }

    const handleUpdateStatus = useMutation({
        mutationFn: (data) => atualizaUsuario(data.id, data.dataToUpdate),
        onSuccess: (response, variables, context) => {
            const user = response.data.data;

            const message = user.status ? 'Colaborador ativado com sucesso.' : 'Colaborador inativado com sucesso.';
            exibirAlerta('Sucesso', message, 'success');

            updateCachedData(user);
        },
    });

    const { mutateAsync: handleDelete } = useMutation({
        mutationFn: deletaUsuario,
        onSuccess: (response, id) => {
            exibirAlerta('Sucesso', 'Colaborador inativado com sucesso.', 'success');
            queryClient.invalidateQueries({ queryKey: queryKeyListCollaborators });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDeleteDialog(false);
        },
    });

    const { mutateAsync: handleResetAcesso } = useMutation({
        mutationFn: resetAcessoUsuario,
        onSuccess: (response) => {
            exibirAlerta('Sucesso', 'Credenciais resetadas com sucesso.', 'success');
            const updatedUser = response.data.data;
            updateCachedData(updatedUser);
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDialogResetUser(false);
        },
    });

    function updateCachedData(updatedUser) {
        // buscar em cache os dados da requisição e atualizar
        const cached = queryClient.getQueryData(queryKeyListCollaborators);
        const cachedData = cached.data;

        if (cached) {
            const updatedData = cachedData.data.map(item => {
                if (item.id === updatedUser.id) {
                    return updatedUser;
                }

                return item;
            });

            queryClient.setQueryData(queryKeyListCollaborators, {
                ...cached,
                data: {
                    ...cachedData,
                    data: updatedData
                }
            });
        }
    }

    async function getDataWithoutPaginateToExport() {
        try {
            exibirDialog('Buscando dados, aguarde...', 'info');
            const response = await listaUsuariosFilter({ id_empresa: searchParams.get('id_empresa') });
            return response.data.data ?? [];
        } catch (error) {
            exibirAlerta('Erro', 'Ocorreu um erro ao buscar os dados.', 'error');
            console.log(error);
            return null;
        } finally {
            handleCloseDialog();
        }
    }

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_pessoas", "list_pessoas", "view_pessoas"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        setSearchParams((state) => {
            state.set('page', String(page + 1));
            state.set('perPage', String(rowsPerPage));

            return state;
        });
    }, [page, rowsPerPage, setSearchParams]);

    useEffect(() => {
        const queryFilters = {
            ...Object.fromEntries(searchParams),
        }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);
    }, [searchParams]);

    useEffect(() => {
        if (!open) {
            setUser(null);
        }
    }, [open]);

    return (
        <>
            <Helmet title="Colaboradores" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="end"
                spacing={3}
            >
                <Grid item xs={12} md={9}>
                    <Breadcrumbs>
                        <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <LinkNavegacao to="/gerenciamento/pessoas">Pessoas</LinkNavegacao>
                        <Typography variant='span'>Colaboradores</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<WorkerIcon fontSize="large" />}
                        title='Colaboradores'
                        description=' Administre os colaboradores de sua(s) empresa(s).'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                        <FiltersButton
                            isOpenFilters={isOpenFilters}
                            setIsOpenFilters={setIsOpenFilters}
                            activeFilters={activeFilters}
                        />

                        {hasPermission(["admin", "admin_pessoas", "create_pessoas"]) &&
                            <TooltipSelectDisabled isDisabled={!isMaxCollaborators} text="Limite máximo de usuários atingido">
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    onClick={() => setFormOpen(true)}
                                    disabled={isMaxCollaborators}
                                >
                                    Cadastrar Colaborador
                                </Button>
                            </TooltipSelectDisabled>
                        }
                    </Stack>
                </Grid>

                <CollaboratorsTableFilter
                    isOpenFilters={isOpenFilters}
                    isLoadingList={isLoadingList}
                    empresas={empresasData?.data?.data ?? []}
                />

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <CustomToolbarExportTable
                            reportName="relatorio_colaboradores"
                            columns={columns}
                            rows={collaborators}
                            getDataWithoutPaginate={getDataWithoutPaginateToExport}
                        />

                        <Table aria-label="Colaboradores">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Matrícula</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Empresa</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingList ? (
                                    <SkeletonTableRow
                                        numCells={6}
                                        numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                    />
                                ) : (
                                    collaborators && collaborators.length === 0 ? (
                                        <EmptyTableRow infoType='colaborador' />
                                    ) : (
                                        collaborators.map((user) =>
                                            <CollaboratorRow
                                                key={user.id}
                                                user={user}
                                                handleUpdateStatus={() => {
                                                    const dataSendUpdate = { id: user.id, dataToUpdate: { status: !user.status } }
                                                    handleUpdateStatus.mutateAsync(dataSendUpdate)
                                                }}
                                                handleOpenViewModal={() => handleViewUser(user)}
                                                handleOpenEditForm={() => handleEditUser(user)}
                                                handleOpenEditTurnAdminForm={() => {
                                                    setTurnAdmin(true)
                                                    handleEditUser(user)
                                                }}
                                                handleOpenDeleteDialog={() => handleOpenDeleteDialog(user)}
                                                handleOpenDialogResetUser={() => handleOpenDialogResetUser(user)}
                                            />
                                        )
                                    )
                                )}
                            </TableBody>
                        </Table>

                        <Pagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        />
                    </TableContainer>
                </Grid>
            </Grid >

            <UserForm
                open={open}
                setOpen={setFormOpen}
                isMaxLimitTipoPessoa={isMaxCollaborators}
                pessoa={user}
                userType='colaborador'
                queryKey={queryKeyListCollaborators}
                changeUserToAdmin={turnAdmin}
            />

            <UserModalInfo
                open={openViewModal}
                setOpen={(event) => {
                    setOpenViewModal(event)
                    if (!event) setUser(null);
                }}
                pessoa={user}
            />

            <ConfirmDialog
                description='Tem certeza que deseja resetar as credenciais de acesso desse colaborador? Isso removerá a senha e a Biometria facial cadastrada.'
                title='Resetar Credenciais'
                goAction={() => handleResetAcesso(user.id)}
                handleClose={() => {
                    setOpenDialogResetUser(false);
                    setUser(null);
                }}
                state={openDialogResetUser}
            />

            <ConfirmDialog
                description='Tem certeza que deseja inativar esse colaborador?'
                title='Inativar Colaborador'
                goAction={() => handleDelete(user.id)}
                handleClose={() => {
                    setOpenDeleteDialog(false);
                    setUser(null);
                }}
                state={openDeleteDialog}
            />
        </>
    );
}

export default ListOfCollaborators;