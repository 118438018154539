import { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { CsvIcon, PdfIcon, Xls502Icon } from './Icons';
import { exportToCsvWithoutPaginate, exportToExcelWithoutPaginate } from '../screens/epis/reports/configs/utils';
import { FileDownloadOutlined } from '@mui/icons-material';

export const CustomToolbarExportTable = ({ reportName, columns, pdfExport, getDataWithoutPaginate, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExportExcel = async () => {
    const data = await getDataWithoutPaginate();
    if (data) {
      exportToExcelWithoutPaginate(reportName, columns, data);
    }
    handleCloseMenu();
  };

  const handleExportCsv = async () => {
    const data = await getDataWithoutPaginate();
    if (data) {
      exportToCsvWithoutPaginate(reportName, columns, data);
    }
    handleCloseMenu();
  };

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        pt: 1,
        px: 1,
        backgroundColor: '#d8d8d8',
      }}
    >
      <Box sx={{ flexGrow: 1 }} />

      <Button
        size="small"
        variant="text"
        startIcon={<FileDownloadOutlined />}
        onClick={handleOpenMenu}
      >
        Exportar
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleExportExcel}>
          <Xls502Icon sx={{ mr: 1 }} /> Exportar como Excel
        </MenuItem>

        <MenuItem onClick={handleExportCsv}>
          <CsvIcon sx={{ mr: 1 }} /> Exportar como CSV
        </MenuItem>

        <MenuItem onClick={pdfExport} disabled={pdfExport === undefined}>
          <PdfIcon sx={{ mr: 1 }} /> Exportar como PDF
        </MenuItem>
      </Menu>
    </Box>
  );
};